import { useDispatch, useSelector } from "react-redux";
import BackBar from "./BackBar";
import Space from "../common/Space";
import CurrencySelect from "../common/CurrencySelect";
import { useEffect, useState } from "react";
import { SpinnerCenter } from "../common/SpinnerCenter";
import QRCode from "react-qr-code";
import { copyClipboard, FormatNumber, GetLogosrc, translateMessage } from "../../helper/constant";
import Copy from "../../assets/images/Copy";
import { Timer } from "./Timer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { getBlockchainWalletAddress, getCurrencyDetails } from "../../store/actions/widgetActions";


const BlockchainWalletAddress = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const [selected, setSelected] = useState<ICheckoutCurrencies | null>(null);

  useEffect(() => {
    if (selected) {
      let data = {
        currencyId: selected.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
        network: selected.currencyNetwork,
        currencyType: 'blockchain'
      };
      let dataAddress = {
        currencyId: selected.currencyId,
        apiKey: modalState.widgetData.apikey,
        network: selected.currencyNetwork,
        checkoutId: modalState.checkoutData.checkoutId
      };
      dispatch(getCurrencyDetails(data, () => {
        dispatch(getBlockchainWalletAddress(JSON.stringify(dataAddress)));
      }, () => {
        setSelected(null)
      }));
    }
  }, [selected]);

  const timeInMs = (expiry: string): number => {
    const date = new Date(expiry);
    const date2 = new Date();
    return date.getTime() - date2.getTime();
  };

  return (
    <>
      <BackBar />
      <CurrencySelect currencies={modalState.checkoutDetails.currencies.filter((item) => item.currencyType === "blockchain")} onSelect={setSelected} selected={selected} />
      {
        <div className="blockchain-screen">
          {selected && <>
            {
              (modalState.blockchainWallet.currencyLoading || !modalState.selectedCurrencyDetails) ?
                <SpinnerCenter text={"Loading Details..."}/> :
                <>
                  {modalState.blockchainWallet.loading ? <SpinnerCenter text={"Loading address"} /> : <>
                  <div className="my-1 mb-2 network-label d-flex justify-content-end align-items-center">
                      {`Network: ${modalState.selectedCurrencyDetails.currencyNetwork}`}
                    </div>
                    <div className="pb-3 qr-sec">
                      <QRCode value={modalState.blockchainWallet.address! || ""} />
                    </div>
                    <div
                      className="copy-payment cursor"
                      onClick={() =>
                        copyClipboard(modalState.blockchainWallet.address!)
                      }
                    >
                      <h4 className="u-color-main">
                        {modalState.blockchainWallet.address}
                      </h4>
                      <div>
                        <Copy />
                      </div>
                    </div>
                    {modalState.blockchainWallet.destinationTag ? (
                      <>
                        <div
                          className="copy-payment cursor"
                          onClick={() =>
                            copyClipboard(modalState.blockchainWallet.destinationTag!)
                          }
                        >
                          <h4>
                            Destination Tag:{" "}
                            {modalState.blockchainWallet.destinationTag}
                          </h4>
                          <div>
                            <Copy />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>}
                  <>
                    <div className="pay-section">
                      <h3>
                        {translateMessage("PAY")}{" "}
                        {FormatNumber(modalState.selectedCurrencyDetails!.total)}{" "}
                        <img
                          src={GetLogosrc(modalState.selectedCurrencyDetails!.currencyName!)}
                          alt="logo"
                        />
                      </h3>
                    </div>
                    <div className="breakdown-section">
                      <div className="breakdown-sec">
                        <h5>{translateMessage("Breakdown")}</h5>
                        {!["completed", "cancelled"].includes(
                          modalState.checkoutDetails.status || "pending"
                        ) &&
                          modalState.checkoutDetails.expiryTime && (
                            <h6>
                              {translateMessage("Expires in")}{" "}
                              <Timer
                                ms={timeInMs(modalState.checkoutDetails.expiryTime!)}
                              />
                            </h6>
                          )}
                      </div>
                      <div className="detail-sec">
                        <div className="row-li">
                          <p>{translateMessage("Amount")}</p>
                          <p>
                            {FormatNumber(modalState.selectedCurrencyDetails!.amount!)}{" "}
                            {modalState.selectedCurrencyDetails!.currencyName}
                          </p>
                        </div>
                        <div className="row-li">
                          <p>{translateMessage("Fee")}</p>
                          <p>
                            {FormatNumber(modalState.selectedCurrencyDetails!.fee!)}{" "}
                            {modalState.selectedCurrencyDetails!.currencyName}
                          </p>
                        </div>
                        <div className="row-li">
                          <p>{translateMessage("Sub Total")}</p>
                          <p>
                            {FormatNumber(modalState.selectedCurrencyDetails!.subTotal!)}{" "}
                            {modalState.selectedCurrencyDetails!.currencyName}
                          </p>
                        </div>
                        {
                          <div className="row-li">
                            <p className="tooltip-div">
                              {translateMessage("Network Surcharge")}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="my-tooltip-id">
                                    By paying using Globiance wallet via mobile app or
                                    netbanking you save on network fees!
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <p>
                                  <FaInfoCircle height={10} width={10} />
                                </p>
                              </OverlayTrigger>
                            </p>
                            <p>
                              {FormatNumber(
                                modalState.selectedCurrencyDetails!.networkSurchargeAmount!
                              )}{" "}
                              {modalState.selectedCurrencyDetails!.currencyName}
                            </p>
                          </div>
                        }
                        <div className="row-li no-bd">
                          <p>{translateMessage("Total")}</p>
                          <p>
                            {FormatNumber(modalState.selectedCurrencyDetails!.total!)}{" "}
                            {modalState.selectedCurrencyDetails!.currencyName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </>
            }
          </>
          }
        </div>
      }
      <Space height={"10px"} width={0} />
    </>
  );
};

export default BlockchainWalletAddress;
