import { axiosInstance, dispatchNotifications } from "../../helper/constant";
import * as actionTypes from "./actionTypes";
import { store } from "../..";

export const updateWidgetScreenState = (state: string): ModalAction => {
  const currentState = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (dispatch: any) =>
    dispatch(updateBackScreenState(currentState.widgetReducer.widgetScreen));
  return {
    type: actionTypes.UPDATE_WIDGET_SCREEN_STATE,
    data: {
      widgetScreen: state,
    },
  };
};

export const updateBackScreenState = (state: string): ModalAction => {
  return {
    type: actionTypes.UPDATE_WIDGET_SCREEN_STATE,
    data: {
      back: state,
    },
  };
};

export const updateWidgetState = (state: string): ModalAction => {
  return {
    type: actionTypes.UPDATE_MODAL_STATE,
    data: {
      widgetState: state,
    },
  };
};

export const updateWidgetParamsState = (state: WidgetParams): ModalAction => {
  return {
    type: actionTypes.UPDATE_WIDGET_PARAMS,
    data: {
      widgetData: state,
    },
  };
};

export const merchantState = (state: MerchantInfo): ModalAction => {
  return {
    type: actionTypes.GET_MERCHANT_STORE_SUCCESS,
    data: {
      merchantData: state,
    },
  };
};

export const widgetError = (state: ErrorState): ModalAction => {
  return {
    type: actionTypes.GET_MERCHANT_STORE_FAIL,
    data: {
      error: state,
    },
  };
};

export const checkoutDataLoading = (state: boolean): ModalAction => {
  return {
    type: actionTypes.GET_CHECHOUT_DATA,
    data: {
      loading: state,
    },
  };
};

export const checkoutDataSuccess = (state: ICheckout): ModalAction => {
  return {
    type: actionTypes.GET_CHECHOUT_DATA_SUCCESS,
    data: {
      checkoutData: state,
    },
  };
};

export const updateWidParamsAndMerchantData = (state: WidgetParams): any => {
  return (dispatch: any) => {
    dispatch(updateWidgetParamsState(state));
    axiosInstance
      .post("/widget/get-store-detail", { apiKey: state.apikey })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(merchantState(res.data.data));
        }
      })
      .catch((err) => {
        const error: string =
          err?.response?.data?.error?.message || "Something went wrong";
        dispatch(
          widgetError({
            error: true,
            errorMessage: error,
            api: "Merchent",
          }),
        );
      });
  };
};

export const selectBlockChainWalletAddress = (state: IQuote): ModalAction => {
  return {
    type: actionTypes.SELECTED_BLOCKCHAIN_WALLET,
    data: {
      blockchainWallet: state,
    },
  };
};

export const getBlockchainWalletAddressRequest = (
  state: boolean,
): ModalAction => {
  return {
    type: actionTypes.GET_BLOCKCHAIN_WALLET_ADDRESS,
    data: {
      blockchainWallet: {
        loading: state,
      },
    },
  };
};

export const getBlockchainWalletAddressSuccess = (
  state: BlockChainWallet,
): ModalAction => {
  return {
    type: actionTypes.GET_BLOCKCHAIN_WALLET_ADDRESS_SUCCESS,
    data: {
      blockchainWallet: state,
    },
  };
};

export const getInstaxWidgetLinkRequest = (
  state: boolean
): ModalAction => {
  return {
    type: actionTypes.GET_INSTAX_WIDGET_LINK,
    data: {
      buyCryptoInfo: {
        loading: state,
      },
    },
  };
};

export const getInstaxWidgetLinkSuccess = (
  state: BuyCryptoInfo
): ModalAction => {
  console.log("state.sessionLink", state)
  return {
    type: actionTypes.GET_INSTAX_WIDGET_LINK_SUCCESS,
    data: {
      buyCryptoInfo: {
        sessionLink: state.sessionLink
      },
    },
  };
};

export const updateTheme = (state: string): ModalAction => {
  return {
    type: actionTypes.UPDATE_THEME,
    data: {
      theme: state,
    },
  };
};

export const getBlockchainWalletAddress = (
  state: any,
  cb?: any,
  cbf?: any,
): any => {
  return (dispatch: any) => {
    dispatch(getBlockchainWalletAddressRequest(true));
    axiosInstance
      .post("/widget/generate-address", state)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getBlockchainWalletAddressSuccess(res.data.data));
          dispatch(getBlockchainWalletAddressRequest(false));
          cb && cb(res.data.data.address);
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_CURRENCY_DETAILS_FAIL"});
        dispatch(getBlockchainWalletAddressRequest(false));
        dispatch(updateWidgetScreenState("Main"));
        dispatchNotifications({
          message: "ERROR WHILE GENERATING ADDRESS",
          color: "red",
        });
        cbf && cbf();
      });
  };
};

export const getInstaxWidgetLink = (
  state: any,
  cb?: any,
  cbf?: any
): any => {
  return (dispatch: any) => {
    dispatch(getInstaxWidgetLinkRequest(true));
    axiosInstance
      .post("/widget/generate-fiat-link-2", state)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getInstaxWidgetLinkSuccess(res.data.data));
          dispatch(getInstaxWidgetLinkRequest(false));
          cb && cb();
        }
      })
      .catch((err) => {
        dispatch(getInstaxWidgetLinkRequest(false));
        // dispatch(updateWidgetScreenState("Main"));
        dispatchNotifications({
          message: "ERROR FETCHING PAYMENT LINK",
          color: "red",
        });
        cbf && cbf();
      });
  };
};

export const updateNotificationAction = (
  state: INotificationState,
): ModalAction => {
  return {
    type: actionTypes.UPDATE_PAYMENT_NOTIFICATION,
    data: {
      paymentNotification: state,
    },
  };
};

const updateCheckoutDetail = (state: ICheckoutDetails): ModalAction => {
  return {
    type: actionTypes.GET_CHECKOUT_DETAIL_SUCCESS,
    data: {
      checkoutDetails: state,
    },
  };
};

export const getCurrencyDetailsSuccess = (state: any): ModalAction => {
  return {
    type: actionTypes.GET_CURRENCY_DETAILS_SUCCESS,
    data: {
      selectedCurrencyDetails: state,
    },
  };
};


export const getCheckoutDetail = (state: any): any => {
  return (dispatch: any) => {
    axiosInstance
      .post("/widget/get-checkout-details", state)
      .then((res) => {
        if (res.status === 200) {
          const storeState = store.getState();

          if (
            storeState.widgetReducer.checkoutDetails?.payments?.length !==
            res.data.data.payments.length
          ) {
            dispatchNotifications({
              message: `PAYMENT UPDATE (${Math.abs(storeState.widgetReducer.checkoutDetails?.payments?.length! - res.data.data.payments.length)})`,
              color: "green",
            });
          }

          if (
            storeState.widgetReducer.checkoutDetails?.status !== "completed" &&
            res.data.data.status === "completed"
          ) {
            dispatch(updateWidgetScreenState("Completed"));
            localStorage.removeItem("checkoutID");
          }

          dispatch(updateCheckoutDetail(res.data.data));

          if (
            res.data.data.status === "completed" ||
            res.data.data.status === "cancelled"
          ) {
            localStorage.removeItem("checkoutID");
          }
        }
      })
      .catch((err) => {});
  };
};

export const getCheckoutDetailFirst = (state: any): any => {
  return (dispatch: any) => {
    dispatch(checkoutDataLoading(true));
    axiosInstance
      .post("/widget/get-checkout-details", state)
      .then((res) => {
        if (res.status === 200) {
          const storeState = store.getState();
          if (
            storeState.widgetReducer.checkoutDetails?.status !== "completed" &&
            res.data.data.status === "completed"
          ) {
            dispatch(updateWidgetScreenState("Completed"));
            localStorage.removeItem("checkoutID");
          }

          if (
            storeState.widgetReducer.checkoutDetails?.payments?.length !==
            res.data.data.payments.length
          ) {
            dispatchNotifications({
              message: `PAYMENT UPDATE (${Math.abs(storeState.widgetReducer.checkoutDetails?.payments?.length! - res.data.data.payments.length)})`,
              color: "green",
            });
          }

          dispatch(updateCheckoutDetail(res.data.data));

          if (
            res.data.data.status === "completed" ||
            res.data.data.status === "cancelled"
          ) {
            localStorage.removeItem("checkoutID");
          }
        }
        dispatch(checkoutDataLoading(false));
      })
      .catch((err) => {
        dispatch(checkoutDataLoading(false));
        const error: string =
          err?.response?.data?.error?.message || "Something went wrong";
        dispatch(
          widgetError({
            error: true,
            errorMessage: error,
            api: "Checkout",
          }),
        );
      });
  };
};

export const getCurrencyDetails = (
  data: any,
  cb:any = null,
  cbf: any
): any => {
  return (dispatch: any) => {
    dispatch({ type: "GET_CURRENCY_DETAILS"});
    dispatch(getBlockchainWalletAddressRequest(true));
    axiosInstance
    .post("/widget/generate-quote", data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCurrencyDetailsSuccess(res.data.data));
      }
      cb && cb();
    })
    .catch((err) => {
      dispatch(getBlockchainWalletAddressRequest(false));
      dispatch({ type: "GET_CURRENCY_DETAILS_FAIL"});
        dispatchNotifications({
          message: "ERROR FETCHING ADDRESS",
          color: "red",
        });
        cbf && cbf();
      });
  };
};