import Cancel from "../../assets/images/Cancel";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../store/actions";
import {
  axiosInstance,
  dispatchNotifications,
  translateMessage,
} from "../../helper/constant";
import { Close } from "../..";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

const CancelScreen = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const [loading, setLoading] = useState(false); // Manage loading state

  const cancelCheckout = async () => {
    setLoading(true); // Start loading
    const data = {
      apiKey: modalState.widgetData.apikey,
      checkoutId: modalState.checkoutData.checkoutId,
    };

    try {
      const res = await axiosInstance.post("/widget/cancel-checkout", data);
      if (res.status === 200) {
        dispatchNotifications({
          message: translateMessage("Checkout Cancellation was successful"),
          color: "green",
        });
        Close();
      }
    } catch (err: any) {
      const error: string =
        err.response?.data?.error?.message || "Something went wrong";
      dispatchNotifications({
        message: error,
        color: "red",
      });
    } finally {
      setLoading(false); // Stop loading
      dispatch(action.updateWidgetScreenState("Main"));
    }
  };

  return (
    <div className="cancel-screen">
      <div>
        <Cancel />
        <h6>
          {translateMessage(
            "Are you sure you want to cancel this transaction?"
          )}
        </h6>
        <div className="btn-grp">
          {/* Disable button when loading */}
          <button
            className={`yes cursor ${loading ? "disabled" : ""}`}
            onClick={cancelCheckout}
            disabled={loading}
          >
            {translateMessage("Yes")} {loading ? <Spinner animation="border" size="sm" /> : <Spinner animation="border" size="sm" className="invisible"/>}
          </button>
          <button
            className="no cursor"
            onClick={() => dispatch(action.updateWidgetScreenState("Main"))}
            disabled={loading} // Disable if loading
          >
            {translateMessage("No")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelScreen;
