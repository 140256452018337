import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import BackBar from "./BackBar";
import * as action from "../../store/actions/index";
import { dispatchNotifications } from "../../helper/constant";
import { getCurrencyDetails } from "../../store/actions/widgetActions";
import { SpinnerCenter } from "../common/SpinnerCenter";
import ErrorImage from "../../assets/images/error-img.png";

function InitiatlizeInstaXchange() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const [error, setError] = useState("");

  function getCryptoCurrency() {
    const exist = modalState.checkoutDetails.currencies
      .filter((item) => "USDT" === item.currencyName)
      .find((item) => "blockchain" === item.currencyType);
    if (exist) return exist;
    return null;
  }

  useEffect(()=>{
    setLoading(true);
    const quote = getCryptoCurrency();
    
    let data = {
      currencyId: quote?.currencyId,
      apiKey: modalState.widgetData.apikey,
      checkoutId: modalState.checkoutData.checkoutId,
      network: quote?.currencyNetwork,
      currencyType: 'blockchain'
    };

    dispatch(getCurrencyDetails(data, () => {
      setLoading(false);
    }, () => {
      setLoading(false);
    }));

    if (!get(quote, "currencyNetwork", null)) {
      const errorMessage = "PLEASE CONTACT MERCHANT TO ENABLE THIS FEATURE";
      dispatchNotifications({
        message: errorMessage,
        color: "red",
      });
      setError(errorMessage);
    }

    return () => {
      dispatch({ type: "GET_CURRENCY_DETAILS_FAIL"});
    }

  }, [])

  useEffect(() => {
    if(modalState.selectedCurrencyDetails) {
      setLoading(true);
      let data = {
        currencyId: modalState.selectedCurrencyDetails.currencyId,
        apiKey: modalState.widgetData.apikey,
        checkoutId: modalState.checkoutData.checkoutId,
        network: modalState.selectedCurrencyDetails.currencyNetwork,
      };
      dispatch(
        action.getBlockchainWalletAddress(data,
          (address: any) => {
            initializeInstaX(address);
            setLoading(false);

          },
          () => {
            setLoading(false);
          }
        ));
    }

  }, [modalState.selectedCurrencyDetails])

  const initializeInstaX = async (address : any) => {
    setLoading(true);

    let body = {
      apiKey: modalState.widgetData.apikey,
      checkoutId: modalState.checkoutData.checkoutId,
      address: address,
      toAmount: modalState.selectedCurrencyDetails!.total,
      fromCurrency: modalState.checkoutDetails.requestCurrency,
      toCurrency: "USDT",
    };

    dispatch(action.getInstaxWidgetLink(body, () => {
      setLoading(false);
    }, () => {
      setLoading(false);

      const errorMessage = "ERROR FETCHING PAYMENT LINK";
      dispatchNotifications({
        message: errorMessage,
        color: "red",
      });
      setError(errorMessage);
    }));
  
  };


  let addressContent;

  if(modalState.blockchainWallet.loading || modalState.buyCryptoInfo.loading || loading) {
    addressContent = (
      <div className="blockchain-screen">
         <SpinnerCenter />
      </div>
    )
  } else if(error) {
    addressContent = (
      <div className="blockchain-screen">
        <div className="error-screen h-vh-40">
          <img className="mt-5" src={ErrorImage} alt="Error" />
          <h6 className="mt-3 text-center">{error}</h6>
        </div>
      </div>
    )
  } else if(modalState.buyCryptoInfo && modalState.buyCryptoInfo.sessionLink) {
    addressContent = (
      <div className="blockchain-screen">
        <div className="instax-wrapper">
          <iframe className="instax-wrapper-iframe" src={modalState.buyCryptoInfo.sessionLink} title="Payment Method"></iframe>
        </div>
      </div>
    )
  }

  return (
    <>
      <BackBar className="py-0" />
      {addressContent}
    </>
  );
}

export default InitiatlizeInstaXchange;
