import { useState, useEffect } from 'react';
import { GetLogosrc } from '../../helper/constant';

type Prop = {
  currencies: ICheckoutCurrencies[];
  selected?: ICheckoutCurrencies | null;
  onSelect: (currency: ICheckoutCurrencies) => void;
};

const CurrencySelect = ({ currencies, selected, onSelect }: Prop) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredCurrencies, setFilteredCurrencies] = useState<ICheckoutCurrencies[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [selectedCurrency, setSelectedCurrency] = useState<ICheckoutCurrencies | null>(selected || null);

  useEffect(() => {
    // Initialize filtered currencies
    setFilteredCurrencies(currencies);
  }, [currencies]);

  useEffect(() => {
    if(searchTerm) {
      const filtered = currencies.filter((currency) => {
        const nameMatch = currency.currencyName.toLowerCase().includes(searchTerm.toLowerCase());
        return nameMatch;
      });
      setFilteredCurrencies(filtered);
    } else {
      setFilteredCurrencies(currencies);
    }
  }, [searchTerm, currencies]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true); // Open dropdown when typing
  };

  const handleSelect = (currency: ICheckoutCurrencies) => {
    setSelectedCurrency(currency);
    onSelect(currency);
    setIsOpen(false);
    setSearchTerm(`${currency.currencyName} ( ${currency.currencyNetwork} )`); // Show the selected currency in the input field
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="currency-select-container">
      <label className="label">Select Currency</label>

      {/* Main Input Field */}
      <div className="search-input-container">
        <input
          type="text"
          placeholder="Search by symbol"
          value={searchTerm}
          onChange={handleSearchChange}
          // onClick={toggleDropdown}
          className="search-input"
        />
        {selectedCurrency && (
          <img
            src={GetLogosrc(selectedCurrency.currencyName)}
            alt="icon"
            className="currency-icon"
          />
        )}


        {/* Dropdown List */}
      {isOpen && filteredCurrencies.length > 0 && (
        <div className="dropdown">
          {filteredCurrencies.map((currency, idx) => (
            <div
              key={`${currency.currencyId} - ${idx}`}
              className="dropdown-item"
              onClick={() => handleSelect(currency)}
            >
              <img
                src={GetLogosrc(currency.currencyName)}
                alt="icon"
                className="currency-icon"
              />
              <span>
                {currency.currencyName}
                {currency.currencyNetwork && ` ( ${currency.currencyNetwork} )`}
              </span>
            </div>
          ))}
          {filteredCurrencies.length === 0 && (
            <div className="no-results">No matching currencies found</div>
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default CurrencySelect;
