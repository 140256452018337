import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Timer } from "./Timer";
import ItemDetails from "./ItemDetails";
import QuotesSections from "./QuotesSections";
import CopyCheckoutID from "./CopyCheckoutID";
import { initNetbanking } from "../../helper/api";
import { Apple } from "../../assets/images/Apple";
import PayAndScanSection from "./PayAndScanSection";
import { GetCurrencySymbol } from "../../helper/misc";
import { Android } from "../../assets/images/Android";
import { translateMessage } from "../../helper/constant";
import { updateWidgetScreenState } from "../../store/actions";
import CardIcon from "../../assets/images/CardIcon";
import * as action from "../../store/actions/index";

const MainPageContent = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const [selectedCurr, setSelectedCurr] = useState<IQuote | null>(null);
  const [itemDetails, setItemDetails] = useState(false);
  const dispatch = useDispatch();

  const timeInMs = (expiry: string): number => {
    const date = expiry ? new Date(expiry) : new Date();
    const date2 = new Date();
    return date.getTime() - date2.getTime();
  };

  const getUniqueQuotes = (): IQuote[] => {
    let quotes = [
      ...modalState.checkoutDetails.quotes.filter(
        (q) => q.currencyType === "blockchain",
      ),
    ];

    const uniqueQuotes = quotes.reduce((acc: any, quote) => {
      const exists = acc.find((cur: any) => {
        return cur.currencyId === quote.currencyId;
      });
      if (exists) {
        if (quote.network) {
          if (exists.network) {
            if (!exists.network.split(",").includes(quote.network))
              exists.network += `,${quote.network}`;
          } else {
            exists.network = `${quote.network}`;
          }
        }
      } else {
        acc.push(quote);
      }

      return acc;
    }, []);

    return uniqueQuotes;
  };

  function getType(str: string, quote: IQuote) {
    const exist = modalState.checkoutDetails.quotes.filter(
      (item) =>
        quote?.currencyId === item.currencyId && str === item.currencyType,
    );
    if (!exist || !exist.length) return null;
    const merged = exist.reduce((acc, cur) => {
      if (!acc.currencyName) acc = cur;
      else {
        if (cur.network && !acc.network?.split(",").includes(cur.network))
          acc.network += `,${cur.network}`;
      }
      return acc;
    }, {});
    return merged;
  }

  const dispatchBC = (quote: IQuote) => {
    dispatch(action.selectBlockChainWalletAddress(quote));
    dispatch(action.updateWidgetScreenState("BlockchainWallet"));
  };

  function otherWalletClick(quote: IQuote) {
    setSelectedCurr(quote);
    dispatchBC(getType("blockchain", quote)!);
  }

  return (
    <div className="main-page">
      <div className="logo-area">
        <div className="d-flex w-100">
          <div className="img-area flex-shrink-0">
            <img src={modalState.merchantData.logo!} alt="logo" />
          </div>
          <div className="title-area flex-grow-1 ms-md-3">
            <div className="label-cls">
              {modalState.merchantData.labelName}
              <br />
              <small>
                {modalState.checkoutDetails.itemName
                  ? `${translateMessage("Pay")} - ${modalState.checkoutDetails.itemName
                  }`
                  : ""}
              </small>
            </div>
            <div className="price-cls text-end">
              {GetCurrencySymbol(modalState.checkoutDetails.requestCurrency!)}{" "}
              {modalState.checkoutDetails.requestCurrencyAmount}
              {!itemDetails ? (
                <small
                  className="detail-txt"
                  onClick={() => setItemDetails(true)}
                >
                  {translateMessage("details")}
                </small>
              ) : (
                <small
                  className="detail-txt invisible"
                  onClick={() => setItemDetails(true)}
                >
                  {translateMessage("...")}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      {itemDetails ? (
        <ItemDetails setItemDetails={setItemDetails} />
      ) : (
        <>
          <div className="payment-opt-sec">
            <h5>{translateMessage("Payment Options")}</h5>
            {!["completed", "cancelled"].includes(
              modalState.checkoutDetails.status || "pending",
            ) &&
              modalState.checkoutDetails.expiryTime && (
                <h6>
                  {translateMessage("Expires in")}{" "}
                  <Timer
                    ms={timeInMs(modalState.checkoutDetails.expiryTime!)}
                  />
                </h6>
              )}
          </div>

          <QuotesSections
            quotes={getUniqueQuotes()}
            onClick={(quote: IQuote) => otherWalletClick(quote)}
            active={selectedCurr?.currencyId}
            classN="main"
          />
          <div className="main-page-bottom">
            <div className="checkout-head mt-2">
              <h4>{translateMessage("Checkout ID")}</h4>

              <div className="cursor">
                <p></p>
              </div>
            </div>
            <CopyCheckoutID className={"top"} />
          </div>
        </>
      )}
    </div>
  );
};

export default MainPageContent;
